<template>
    <div>
      <div>
        <el-row class="bgW">
        <el-col :span="20">
          <!--检索条件-->
          <el-form class="search" :inline="true" label-suffix="：" size="mini" :model="search">

            <el-form-item label="日期">
              <el-date-picker
                v-model="search.start_time"
                type="date"
                class="w130"
                placeholder="开始日期"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
              <el-date-picker
                v-model="search.end_time"
                type="date"
                class="w130"
                placeholder="结束日期"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </el-form-item>

            <el-form-item label="门店名称" >
              <el-input
                v-model="search.shop_name"
                maxlength="30"
                class="w84"
                placeholder="请输入"
                clearable
              />
            </el-form-item>
            <el-form-item label="项目明细"
            v-if="status">
              <el-select

                    v-model="search.goods_name"
                    size="mini"
                    value-key="name"
                    clearable
                    placeholder="请选择商品"
                    filterable
                    :remote-method="remoteMethod"
                  >
                    <el-option
                      v-for="item in product"
                      :key="item.name"
                      :label="item.name"
                      :value="item.name"
                    />
                  </el-select>
              </el-form-item>

            <el-form-item label="项目大类" >
              <el-select
                v-model="option_value"
                multiple
                collapse-tags
                style="margin-left: 20px;"
                placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
              <!-- <el-select v-model="option_value" multiple placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select> -->
            </el-form-item>
<!--            <el-form-item label="门店类型" prop="type">
              <el-select
                v-model="search.type"
                class="w100"
                clearable
                placeholder="门店类型"
              >
                <el-option
                  v-for="item in options.type"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item> -->


          </el-form>
        </el-col>
        <el-col :span="4" class="btnBox">
          <el-switch
            v-model="status"
            @change="changeStatus(status)"
        >
          </el-switch>
          <el-button type="primary" icon="el-icon-search" size="mini" @click="getList('search')">搜索</el-button>
          <el-button type="primary" class="mb10" size="mini"  :loading="exportS" @click="exportExcel">导出数据</el-button>
          <!-- <el-button type="primary" class="mb10" size="mini"  :loading="exportS" @click="exportExcel">导出数据</el-button> -->
        </el-col>
      </el-row>
      <!--列表-->
      <el-row>
        <el-col :span="24">
          <el-table
            :data="list"
            height="700"
            size="small"
            border
            v-loading="loading"
            :cell-style="$style.cellStyle"
            :header-cell-style="$style.rowClass"
          >
            <el-table-column label="时间">
              <template slot-scope="{ row }">
                {{ search.start_time}}-{{ search.end_time }}
              </template>
            </el-table-column>
            <ElTableColumn label="市场" prop="market_name" />
            <!-- <ElTableColumn label="店面" prop="shop_name" /> -->
            <el-table-column label="店面">
              <template slot-scope="{ row }">
                <div
                class="hand"
                @click="clickBtn(row,row.shop_id)"
                >{{row.shop_name}}</div>
              </template>
            </el-table-column>
            <el-table-column label="项目大类">
              <template slot-scope="{ row }">
                {{ goods_type[row.goods_type]}}
              </template>
            </el-table-column>
            <ElTableColumn
            v-if="status"

            label="项目明细" prop="goods_name" />
            <ElTableColumn label="购买人数" prop="number" />
			      <ElTableColumn label="复购次数" prop="repurchase_num" />
            <ElTableColumn label="销售金额" prop="total_amount" />
          </el-table>

        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { getLastDay } from '@/utils';
import pagination from '@/components/Pagination'
import {productAnalysis,getGoodsList} from "@/api/statistics";
import { handleExportExcel } from '@/vendor/handleExportExcel';

class Page {
  total = 0;
  page = 1;
  limit = 10;
}
class Search {
  start_time = "";
  end_time = "";
  shop_name = "";
  goods_name = "";
  type = "";
}

export default {
  name: 'productAnalysis',
  components: {
    pagination
  },
  data () {
    return {
      search: new Search(), // 检索条件
      page: new Page(), // 分页
      list: [], // 数据展示
      loading:false,
      exportS: false,
      options: [
        {
          value: 1,
          label: '补水保湿'
        },
        {
          value: 2,
          label: '祛黄提亮'
        },
        {
          value: 3,
          label: '控油祛痘'
        },
        {
          value: 4,
          label: '安舒修复'
        },
        {
          value: 5,
          label: '面部仪器'
        },
        {
          value: 6,
          label: '面部手法'
        },
        {
          value: 7,
          label: '身体仪器'
        },
        {
          value: 8,
          label: '身体手法'
        },
      ],
      option_value:[1,2,3,4,5,6,7,8],
      goods_type:{
        1:"补水保湿",
        2:"祛黄提亮",
        3:"控油祛痘",
        4:"安舒修复",
        5:"面部仪器",
        6:"面部手法",
        7:"身体仪器",
        8:"身体手法"
      },
      status : false,
      is_detail : 0,
      name:"",
      product:[]
    }
  },

  mounted () {
    let year = new Date().getFullYear()
    let month = new Date().getMonth() + 1
    if(month.toString().length == 1){
      month = '0'+ month
    }
    let day = getLastDay(year,month)
    if(day.toString().length == 1){
      day = '0'+ day
    }
    this.search.start_time = year+'-'+ month + '-01'
    this.search.end_time = year+'-'+ month + '-' + day
    this.getList();
  },
  methods: {
    getList() {
      this.loading = true
      productAnalysis({
        'start_time':this.search.start_time,
        'end_time':this.search.end_time,
        'type':this.is_detail,
        "goods_type": this.option_value,
        "shop_name":this.search.shop_name,
        "goods_name":this.search.goods_name
      }).then(res => {

		    this.list = res.data;
        this.loading = false

      });
    },
    remoteMethod (e) {
      this.getGoods(e)
    },
	clickBtn(row,type){
	  //console.log(row);shop_name
	  const query = {

      type: 1,
      shop_id: row.shop_id,
      shopName: row.shop_name,
      start_time: this.search.start_time ,
      end_time: this.search.end_time,
      goods_type: [1,2,3,4,5,6,7,8]

	  };
	  //console.log(query);
	  // this.$router.push({ name: "品项分析数据明细", query });
    let route = this.$router.resolve({ name: "品项分析数据明细", query });
    window.open(route.href, '_blank');
	},
  changeStatus(newstatus){
    if(newstatus)
    {
      this.is_detail = 1;
    }else{
      this.is_detail = 0;
      this.search.goods_name = "";
    }
    this.getList();
    this.getGoods();
    // console.log(this.status);
  },
  // 导出表格
  async exportExcel() {
    this.exportS = true
    const { data } = await productAnalysis({
       'start_time':this.search.start_time,
       'end_time':this.search.end_time,
       'type':this.is_detail,
       "goods_type": this.option_value,
       "shop_name":this.search.shop_name,
       "goods_name":this.search.goods_name,
       "export":1
    });
    handleExportExcel(
      [
        '时间',
        '市场名称',
        '店面名称',
        '项目大类',
        '购买人数',
        '复购次数',
        '销售金额',
      ],
      [
        'date',
        'market_name',
        'shop_name',
        'goods_type',
        'number',
        'repurchase_num',
        'total_amount',
      ],
      data,
      '品项分析'
    );
    this.exportS = false
  },
  getGoods (name) {
    getGoodsList({ page: 1, limit: 100000, zu_name: name || '' }).then(
        res => {
          this.product = res.data;
        }
      )
    },

  }
}
</script>

<style lang="scss" scoped>
.btnBox {
  text-align: right;
}

.hand {
  cursor: pointer;
}
</style>
